import React, { useState } from 'react';
import axios from 'axios';
import './AccountNumberFinder.css';
import AddStatistics from '../utils/AddStatistics';


const FIND_ACCOUNT_NUMBER_URL = "https://dubkibobri.freemyip.com/find-account-number"; // process.env.REACT_APP_FIND_ACCOUNT_NUMBER_URL;


function AccountNumberFinder({ onClose, userID, regionID }) {
    const [oldAccountNumber, setOldAccountNumber] = useState('');
    const [newAccountNumbers, setNewAccountNumbers] = useState([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false); // Для индикации загрузки

    const findAccountNumber = async () => {
        if (!oldAccountNumber.trim()) {
            setError('Пожалуйста, введите старый номер счета.');
            return;
        }
        setIsLoading(true);
        try {
            const response = await axios.get(`${FIND_ACCOUNT_NUMBER_URL}?old_account_number=${encodeURIComponent(oldAccountNumber)}`);
            setNewAccountNumbers(response.data);
            setError('');
            AddStatistics(userID, regionID, 6);
        } catch (err) {
            setError('Ошибка при поиске номера счета: ' + (err.response?.data?.message || err.message));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="account-number-finder">
            <div className="account-number-finder-card">
                <h3 className="section-header">Узнайте ваш новый номер счета</h3>
                <div>
                    <input
                        id="old-account-number"
                        type="text"
                        value={oldAccountNumber}
                        onChange={e => setOldAccountNumber(e.target.value)}
                        placeholder="Введите старый номер счета"
                        className="account-number-finder-form-input"
                    />
                </div>
            </div>

            {isLoading && <p>Загрузка...</p>}

            {newAccountNumbers.length > 0 && (
                <div className="new-account-number-section">
                    <h3 className="section-header">Новый номер лицевого счета</h3>
                    <input
                        id="new-account-number"
                        type="text"
                        value={newAccountNumbers.join(", ")}
                        readOnly
                        className="account-number-finder-form-input"
                    />
                </div>
            )}
            <div className="account-number-finder-form-actions">
                <button className="account-number-finder-button" onClick={onClose}>Назад</button>
                <button className="account-number-finder-button" onClick={findAccountNumber}>Узнать</button>
            </div>

            {error && <p className="account-number-finder-error-message">{error}</p>}

            {/*<br/>*/}
            {/*<span>{userID}</span>*/}
        </div>
    );
}

export default AccountNumberFinder;
