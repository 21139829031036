/* global Telegram */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AccountNumberFinder from './components/AccountNumberFinder';
import CounterFinder from './components/CounterFinder';
import AddStatistics from './utils/AddStatistics';
import './App.css'; // Убедитесь, что путь до файла стилей указан верно

// Пути к изображениям логотипов
import logoColor from './static/logo_color-3.png';
import logoWhite from './static/logo_white-3.png';

const AppVersion = "20240108";

const REGIONS_URL = "https://dubkibobri.freemyip.com/regions"; // process.env.REACT_APP_REGIONS_URL;
const REGION_LINKS_URL = "https://dubkibobri.freemyip.com/region-links"; // process.env.REACT_APP_REGION_LINKS_URL;
const FAQS_URL = "https://dubkibobri.freemyip.com/faqs"; // process.env.REACT_APP_FAQS_URL;


function App() {
    const [regions, setRegions] = useState({});
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [regionID, setRegionID] = useState(0);
    const [faqs, setFAQs] = useState({});
    const [error, setError] = useState('');
    const [showMenu, setShowMenu] = useState(false);
    const [showFAQs, setShowFAQs] = useState(false);
    const [openedFAQ, setOpenedFAQ] = useState(null);
    const [regionLinks, setRegionLinks] = useState({});
    const [showAccountFinder, setShowAccountFinder] = useState(false);
    const [showCounterFinder, setShowCounterFinder] = useState(false);
    const [isLightTheme, setIsLightTheme] = useState(true);
    const [userID, setUserID] = useState("");
    const [userUserName, setUserUserName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [userFirstName, setUserFirstName] = useState("");
    const [userLanguageCode, setUserLanguageCode] = useState("");

    useEffect(() => {
        axios.get(REGIONS_URL)
            .then(response => setRegions(response.data))
            .catch(err => setError("REGIONS_URL: " + REGIONS_URL + "" + err.message));
    }, []);

    useEffect(() => {
        const handleThemeChange = (theme) => {
            setIsLightTheme(theme === 'light');
        };

        if (window.Telegram.WebApp) {
            Telegram.WebApp.onEvent('themeChanged', handleThemeChange);
        }

        return () => {
            if (window.Telegram.WebApp) {
                Telegram.WebApp.offEvent('themeChanged', handleThemeChange);
            }
        };
    }, []);

    useEffect(() => {
        if (typeof Telegram !== 'undefined' && Telegram.WebApp) {
            Telegram.WebApp.ready();
            console.log("Telegram ready");

            let tg = window.Telegram.WebApp;
            if (tg.initDataUnsafe.user) {
                if (tg.initDataUnsafe.user.id) {
                    setUserID(tg.initDataUnsafe.user.id.toString());
                }
                if (tg.initDataUnsafe.user.username) {
                    setUserUserName(tg.initDataUnsafe.user.username.toString());
                }
                if (tg.initDataUnsafe.user.first_name) {
                    setUserFirstName(tg.initDataUnsafe.user.first_name.toString());
                }
                if (tg.initDataUnsafe.user.last_name) {
                    setUserLastName(tg.initDataUnsafe.user.last_name.toString());
                }
                if (tg.initDataUnsafe.user.language_code) {
                    setUserLanguageCode(tg.initDataUnsafe.user.language_code.toString());
                }
            }

            Telegram.WebApp.onEvent('viewportChanged', (event) => {
            });
        }
        if (window.Telegram.WebApp) {
            Telegram.WebApp.onEvent('themeChanged', (theme) => {
                document.documentElement.style.setProperty('--primary-color', theme.bg_color);
                document.documentElement.style.setProperty('--secondary-color', theme.secondary_bg_color);
                document.documentElement.style.setProperty('--text-color', theme.text_color);

            });
        }

        const updateThemeVariables = (theme) => {
            document.documentElement.style.setProperty('--primary-color', theme.bg_color);
            document.documentElement.style.setProperty('--secondary-color', theme.secondary_bg_color);
            // ...другие переменные
        };

        if (window.Telegram.WebApp) {
            Telegram.WebApp.ready();
            Telegram.WebApp.MainButton.hide();

            // Обновляем переменные CSS при изменении темы
            Telegram.WebApp.onEvent('themeChanged', updateThemeVariables);
        }
    }, []);

    // Функция для изменения класса темы в зависимости от настроек Telegram
    const setThemeClass = () => {
        if (window.Telegram.WebApp.themeParams?.bg_color) {
            document.body.className = 'light-theme';
        } else {
            document.body.className = 'dark-theme';
        }
    };

    const handleRegionSelect = (regionKey) => {
        setSelectedRegion(regionKey);
        setShowMenu(true);
        setShowFAQs(false);
        setShowAccountFinder(false);

        // region_1
        let tmp = regionKey.split("_");
        if (tmp.length > 1) {
            setRegionID(tmp[1]);
        }

        axios.get(`${REGION_LINKS_URL}?user_id=${userID}&region=${regionKey}`)
            .then(response => {
                setRegionLinks(response.data);
            })
            .catch(err => setError(`Ошибка загрузки ссылок: ${err.message}`));
    };

    const handleFAQSelect = (pageID, faqType) => {
        axios.get(`${FAQS_URL}?user_id=${userID}&region=${selectedRegion}&type=${faqType}`)
            .then(response => {
                setFAQs(response.data);
                setShowFAQs(true);
            })
            .catch(err => setError(`Ошибка загрузки FAQ: ${err.message}`));

        AddStatistics(userID, regionID, pageID);
    };

   const handleShowAccountFinder = (pageID) => {
       setShowAccountFinder(true);

       // В компоненте отмечаются успешные поиски
       AddStatistics(userID, regionID, pageID);
   };

    const handleShowCounterFinder = (pageID) => {
        setShowCounterFinder(true);

        // В компоненте отмечаются успешные поиски
        AddStatistics(userID, regionID, pageID);
    };

    const handleLink = (pageID, link, dedicated) => {
        if (dedicated) {
            window.location.href = link;
        } else {
            window.open(link, "_blank", "noopener,noreferrer");
        }

        AddStatistics(userID, regionID, pageID);
    };

    const handleBackToRegions = () => {
        setSelectedRegion(null);
        setShowMenu(false);
        setShowFAQs(false);
        setShowAccountFinder(false);
        setFAQs({});
    };

    const handleBackToMenu = () => {
        setShowFAQs(false);
        setFAQs({});
        setShowAccountFinder(false);
    }

    const handleToggleFAQ = (questionKey) => {
        setOpenedFAQ(openedFAQ === questionKey ? null : questionKey);
    };

    return (
        <div className="app-wrapper">
            <header className="app-header-h4">
                {/*<div className="logo">*/}
                {/*    <img src={isLightTheme ? logoColor : logoWhite} alt="Logo" />*/}
                {/*</div>*/}
                <h2 className="app-header-h2-bot">ЭНЕРГОСБЫТ</h2>
                {!selectedRegion && <h4 className="app-title">Выберите регион</h4>}
                {selectedRegion && !showFAQs && !showAccountFinder && !showCounterFinder && (
                    <div className="region-header">
                        <h4 className="app-title">Ваш регион: {regions[selectedRegion]}</h4>
                    </div>
                )}
            </header>

            {error && <p className="error-message">{error}</p>}

            <main className="app-content">
                {/*TODO: Вынести в компонент*/}
                {!showMenu && (
                    <div className="app-region-selection" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 'var(--element-spacing)' }}>
                        {Object.entries(regions).map(([key, name]) => (
                            <button key={key} onClick={() => handleRegionSelect(key)} className="app-button region-button" style={{ width: '100%', maxWidth: '300px' }}>
                                {name}
                            </button>
                        ))}
                    </div>
                )}

                {showMenu && !showFAQs && !showAccountFinder && !showCounterFinder && (
                    <nav className="app-main-menu">
                        {/*pageID 1*/}
                        <button onClick={() => handleFAQSelect(1, 'common')} className="app-button faq-button">❓ Популярные вопросы</button>
                        {/*pageID 2*/}
                        <button onClick={() => handleFAQSelect(2, 'bill')} className="app-button faq-button">🧾❓ Вопросы по квитанции</button>
                        {/*pageID 3*/}
                        {/*<button className="app-button reminder-button">🗓 Напомнить о передаче показаний</button>*/}
                        {/*pageID 4*/}
                        <button onClick={() => handleLink(4, regionLinks.site, false)} className="app-link-button site-button">🌐 Перейти на сайт</button>
                        {/*pageID 5, 6*/}
                        <button onClick={() => handleShowAccountFinder(5)} className="app-button account-finder-button">🔍 Узнать свой номер лицевого счета</button>
                        {/*pageID 7*/}
                        <button onClick={() => handleLink(7, regionLinks.pay, true)} className="app-link-button pay-button">💸 Перейти к оплате</button>
                        {/*pageID 8, 9, 10*/}
                        <button onClick={() => handleShowCounterFinder(8)} className="app-button account-finder-button">💡 Передать показания</button>
                        {/*pageID 11*/}
                        <button onClick={() => handleLink(11, regionLinks.vk, false)} className="app-link-button vk-button">💬 Написать обращение</button>
                        {/*pageID 12*/}
                        <button onClick={handleBackToRegions} className="app-button back-button">Назад</button>
                    </nav>
                )}

                {showCounterFinder &&
                    <CounterFinder onClose={() => setShowCounterFinder(false)}
                                   selectedRegionName={regions[selectedRegion]}
                                   userID={userID}
                                   regionID={regionID}/>}

                {/*TODO: Вынести в компонент*/}
                {showFAQs && (
                    <div className="app-faq-list">
                        {Object.entries(faqs).map(([question, answer]) => (
                            <div key={question} className={`faq-item card ${openedFAQ === question ? 'active' : ''}`} onClick={() => handleToggleFAQ(question)} style={{ cursor: 'pointer' }}>
                                <div className="faq-question">
                                    <span>{question}</span>
                                    <span className="faq-icon">{openedFAQ === question ? '−' : '+'}</span>
                                </div>
                                {openedFAQ === question && (
                                    <div className="faq-answer">
                                        {answer}
                                    </div>
                                )}
                            </div>
                        ))}
                        <button onClick={handleBackToMenu} className="app-button back-button">Назад к меню</button>
                    </div>
                )}

                {showAccountFinder &&
                    <AccountNumberFinder onClose={() => setShowAccountFinder(false)}
                                         userID={userID}
                                         regionID={regionID}/>}

                {/*<br/>*/}
                {/*<span>{userID}</span>*/}
                {/*<br/>*/}
                {/*<span>{userUserName}</span>*/}
                {/*<br/>*/}
                {/*<span>{userLastName}</span>*/}
                {/*<br/>*/}
                {/*<span>{userFirstName}</span>*/}
                <br/>
                <span>{AppVersion}</span>
            </main>
        </div>
    );
}

export default App;
